<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="appLogo"
              max-height="1.666667rem"
              max-width="1.666667rem"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">
              {{ appName }}
            </h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            Account Login 🔐
          </p>
          <p class="mb-2">
            Please sign-in to access the KEPSA vaccine portal
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form
            ref="loginForm"
            @submit.prevent="handleFormSubmit"
          >
            <v-text-field
              v-model="email"
              outlined
              label="Email"
              placeholder="email"
              :error-messages="errorMessages.email"
              :rules="[validators.required, validators.emailValidator]"
              hide-details="auto"
              class="mb-3"
            ></v-text-field>

            <v-text-field
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Password"
              :error-messages="errorMessages.password"
              placeholder="Password"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
              :rules="[validators.required]"
              hide-details="auto"
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <div class="d-flex align-center justify-space-between flex-wrap">
              <v-checkbox
                hide-details
                label="Remember Me"
                class="me-3 mt-1"
              >
              </v-checkbox>

              <!-- forget link -->
              <router-link
                :to="{name:'auth-forgot-password'}"
                class="mt-1"
              >
                Forgot Password?
              </router-link>
            </div>

            <v-btn
              block
              color="primary"
              class="mt-6"
              type="submit"
            >
              Login
            </v-btn>
          </v-form>
        </v-card-text>

        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <span class="me-2">
            Don't have login account?
          </span>
          <router-link :to="{name:'auth-register'}">
            Request Access
          </router-link>
        </v-card-text>

        <!-- divider -->
        <v-card-text class="d-flex align-center mt-2">
          <v-divider></v-divider>
          <span class="mx-5">or</span>
          <v-divider></v-divider>
        </v-card-text>

        <!-- social links -->
        <v-card-actions class="d-flex justify-center">
          <v-btn
            v-for="link in socialLink"
            :key="link.icon"
            icon
            class="ms-1"
          >
            <v-icon :color="$vuetify.theme.dark ? link.colorInDark : link.color">
              {{ link.icon }}
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >

    <!-- tree -->
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref, getCurrentInstance } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import axios from 'axios'
import { useRouter } from '@core/utils'
import themeConfig from '@themeConfig'

export default {
  setup() {
    // Template Ref
    const loginForm = ref(null)

    const vm = getCurrentInstance().proxy
    const { router } = useRouter()

    const isPasswordVisible = ref(false)

    const email = ref('admin@kepsa.or.ke')
    const password = ref('password')
    const errorMessages = ref([])
    const roles = ref(['1', '3'])
    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    const handleFormSubmit = () => {
      const isFormValid = loginForm.value.validate()

      if (!isFormValid) return
      axios
        .post(
          `${vm.$config.chmApp.baseUrl}${vm.$config.chmApp.version}/auth/login`,
          { email: email.value, password: password.value },
          { headers: { 'Content-Type': 'application/json' } },
        )
        .then(response => {
          const accessToken = response.data['access_token']
          const role = response.data['user']['roles'][0]
          const user = response.data['user']

          //Check if user has roles
          if (!roles.value.includes(role['id'])) {
            errorMessages.value = { email: 'You do not have permissions to access this resource' }
            return response
          }

          //Check if user is active
          if (user['status']['id'] !== '1') {
            errorMessages.value = { email: 'Account disabled. Contact the administrator.' }
            return response
          }
          // check user role and add abilities
          if (role['id'] === '1') {
            const ability = [
              {
                action: 'manage',
                subject: 'all',
              },
            ]

            user['role'] = 'admin'
            vm.$ability.update(ability)
            localStorage.setItem('userAbility', JSON.stringify(ability))
          } else if (role['id'] === '3') {
            const ability = [
              {
                action: 'read',
                subject: 'ACL',
              },
              {
                action: 'read',
                subject: 'Demo',
              },
              {
                action: 'read',
                subject: 'Public',
              },
            ]

            user['role'] = 'client'
            vm.$ability.update(ability)
            localStorage.setItem('userAbility', JSON.stringify(ability))
          }

          if (user.avatar !== '') {
            // user['avatar'] = require(`@/assets/images/avatars/${user.avatar}`)
          }

          user['fullName'] = `${response.data['user'].names}`

          localStorage.setItem('userData', JSON.stringify(user))
          localStorage.setItem('accessToken', accessToken)
          router.push('/')

          return response
        })
        .catch(error => {
          console.log(error)
          // errorMessages.value = { email: error.response.data['message'] }
        })
    }

    return {
      handleFormSubmit,

      isPasswordVisible,
      email,
      password,
      roles,
      errorMessages,
      socialLink,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validators: {
        required,
        emailValidator,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // Template Refs
      loginForm,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
